<script>
import HeroBar from '@/components/HeroBar.vue'
import TitleBarNew from '@/components/TitleBarNew.vue'

export default {
  name: 'ShipmentType',
  components: { TitleBarNew, HeroBar },
  data () {
    return {
      titleStack: [
        {
          name: 'Dashboard',
          routeName: 'home',
          params: []
        },
        {
          name: 'Modes',
          routeName: 'shipment.types'
        },
        {
          name: this.$route.params.type
        }
      ],
      years: []
    }
  },
  methods: {
    fetch () {
      this.axios.get(`/v2/app/invoice-years/${this.$route.params.type}`)
        .then(response => {
          this.years = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    this.fetch()
  }
}
</script>

<template>
  <div>
    <title-bar-new :title-stack="titleStack" />
    <hero-bar>
      {{ $route.params.type }}
    </hero-bar>

    <section class="section is-main-section has-background-white">
      <div class="mb-2 p-4">
        <div class="columns is-multiline is-mobile">
          <div v-for="(year, idx) in years" :key="idx" class="column is-one-quarter">
            <router-link :to="{name: 'shipment.year', params: {year: year, type: $route.params.type}}">{{ year}}</router-link>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
